<script>
import axios from 'axios';
import { orderCategories } from '@/misc.js';
import {i18n} from "vue-lang-router";

export default {
  name: 'library-start',

  data() {
    return {
      categories: true,
    };
  },

  created() {
    const lang = i18n.locale;
    axios
      .get(`${process.env.VUE_APP_BACKEND_URL}/api/category/${lang}`)
      .then((res) => {
        this.categories = orderCategories(res.data);
      })
      .catch(console.error);
    document.title = i18n.t("tempus");

  },
  methods: {
    getCategoryImagePath(extId) {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/categories/${extId}.jpg`);
    },
  },
};
</script>

<template>
  <div style="background: white !important;">
  <div class="start-text"> {{ $i18n.locale == 'en' ? "The pictures of Tempus Picture Agency are arranged in twelve top categories." :  "Bilderna i Tempus Bildbyrå är ordnade i tolv toppkategorier." }}</div>
  <div class="start-box bg-white" style="width: calc(100vw - 100px) !important; min-width: unset !important; display: flex; flex-wrap: wrap; gap: 16px; margin-top: 50px; margin-left: 50px;  align-items: center; place-content: flex-start; background: white">
    <localized-link
      v-for="(category, i) in categories"
      class="block text-2xl mb-2 md:text-3xl hover:text-blue-500" style="height: 180px"
      :to="{ name: 'library',  params: { category: $i18n.locale == 'en' ? category.full_path_en.toLowerCase().split('/') : category.full_path_sv.toLowerCase().split('/') } }"
      :key="i"
    >
      <img :src="getCategoryImagePath(category.ext_id)">
      <span style="font-size: 16px"> {{ $i18n.locale == 'en' ? category.title_en :  category.title_sv }} ({{ category.images_count }})</span>
    </localized-link>
  </div>
  </div>
</template>

<style lang="scss" scoped>
.library {

  .cat-link {

    &:hover {
      color: $dark-blue;
    }
  }
}

.start-text {
  text-align: left;
  font-size: 14px;
  margin-top: 50px;
  margin-left: 50px;
}
</style>
